import { TabBtn } from '@playbooks/interface/buttons';
import { Div } from '@playbooks/interface/html';
import { capitalize } from 'utils';

const SearchResultsSubnav = ({ selected, options = [], onSelect }) => {
	// Methods
	const isActive = value => {
		return selected === value;
	};

	// Render
	if (options.length === 0) return null;
	return (
		<Div border='border-b' display='flex-start' space='space-x-2' overflow='overflow-x-scroll'>
			<TabBtn size='p-3' active={isActive('')} onClick={() => onSelect('')}>
				All
			</TabBtn>
			{options.map((option, i) => (
				<TabBtn key={i} size='p-3' active={isActive(option)} onClick={() => onSelect(option)}>
					{capitalize(option)}
				</TabBtn>
			))}
		</Div>
	);
};

export { SearchResultsSubnav };
