import { Fragment, useEffect, useState } from 'react';

import { SearchAllSection } from 'components/search/search-all-section';
import { SearchRecentSection } from 'components/search/search-recent-section';
import { SearchTrendingSection } from 'components/search/search-trending-section';
import { useSearch, useStorage } from 'contexts';

const SearchResultsPreview = ({ query, hits = [], open, loading, rootLink = '', tableName, tableNames }) => {
	const [recent, setRecent] = useState([]);
	const search = useSearch();
	const storage = useStorage();

	// Hooks
	useEffect(() => {
		const queries = storage.getValue('search') || [];
		const results = queries
			.filter(v => (tableName ? tableName === v.modelName : true))
			.filter(v => (tableNames.length > 0 ? tableNames.includes(v.modelName) : true))
			.sort((a, b) => (a.date < b.date ? 1 : -1));
		setRecent(results);
	}, [storage.storage.search, tableName, tableNames]);

	// Render
	return (
		<Fragment>
			{query.length >= 3 ? (
				<SearchAllSection
					hits={hits}
					query={query}
					loading={loading}
					onClick={result => search.onClick(rootLink, result)}
				/>
			) : (
				<Fragment>
					{recent.length > 0 ? (
						<SearchRecentSection
							api={{ page: 0, pageSize: 6 }}
							open={open}
							tableName={tableName}
							tableNames={tableNames}
							onClick={result => search.onClick(rootLink, result)}
						/>
					) : (
						<SearchTrendingSection
							query={query}
							api={{ page: 0, pageSize: 6 }}
							open={open}
							tableName={tableName}
							tableNames={tableNames}
							onClick={result => search.onClick(rootLink, result)}
						/>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export { SearchResultsPreview };
